import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuComponent } from './menu/menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { MenuCategoryComponent } from './menu-category/menu-category.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { RestaurantComponent } from './restaurant/restaurant.component';
import { AngularFireRemoteConfigModule, DEFAULTS, SETTINGS } from '@angular/fire/remote-config';
import { WebStorageModule } from 'ngx-store';
import {FlexLayoutModule} from '@angular/flex-layout';
import { CartComponent } from './cart/cart.component';
import { OrdersComponent } from './orders/orders.component';
import { CartItemComponent } from './cart/cart-item/cart-item.component';
import { ItemSumPipe } from './pipes/item-sum.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';



@NgModule({
  declarations: [
    AppComponent,
    MenuItemComponent,
    MenuComponent,
    MenuCategoryComponent,
    RestaurantComponent,
    CartComponent,
    OrdersComponent,
    CartItemComponent,
    ItemSumPipe,
    SortByPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule, // dynamically imports firebase/analytics
    AngularFireRemoteConfigModule,
    AngularFirestoreModule,
    AngularFirePerformanceModule,
    WebStorageModule,
    FlexLayoutModule,
  ],
  providers: [
    { provide: DEFAULTS, useValue: { enableAwesome: true } },
    {
      provide: SETTINGS,
      useFactory: () => isDevMode() ? { minimumFetchIntervalMillis: 10_000 } : {}
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
