import { Component, OnInit, Input } from '@angular/core';
import { SharedStorage, WebstorableObject } from 'ngx-store';
import { CartItem, Cart } from '../../interfaces/cart';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit {

  @Input() item: CartItem;
  @Input() index: number;
  @SharedStorage() cart: WebstorableObject & Cart;

  constructor() { }

  ngOnInit() { }

  deleteItem() {
    this.cart.items.splice(this.index, 1);
    this.cart.save();
  }

}
