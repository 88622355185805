import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireRemoteConfig, Value } from '@angular/fire/remote-config';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { LocalStorage, SharedStorage } from 'ngx-store';
import { Menu } from '../interfaces/menu';
import { Order } from '../interfaces/order';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.scss']
})
export class RestaurantComponent implements OnInit {

  @LocalStorage() cart: { items: any[] } = { items: [] };
  @LocalStorage() darkmode: boolean;
  @SharedStorage() menu: Menu;
  @LocalStorage() orders: Order[] = [];

  restaurant$: Observable<any>;
  config$: Observable<any>;

  topbarColor: string;

  private restaurantDoc: AngularFirestoreDocument<any>;

  constructor(
    db: AngularFirestore,
    config: AngularFireRemoteConfig,
    route: ActivatedRoute,
  ) {
    config.ensureInitialized().then(() => {
      config.getValue('topbar_color').then((value: Value) => {
        this.topbarColor = value.asString();
      });
    });

    route.params.pipe(
      map(params => params.code),
    ).subscribe(code => {
      this.restaurantDoc = db.collection('restaurants').doc<any>(code);
      this.restaurantDoc.valueChanges().subscribe(restaurant => {
        this.menu = restaurant;
      });
    });
  }

  ngOnInit() { }

  toggleDarkmode() {
    this.darkmode = !this.darkmode;
  }

}
