import { Component, OnInit, Input } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { LocalStorage, Webstorable } from 'ngx-store';
import { MenuItem } from '../interfaces/menu';
import { Cart } from '../interfaces/cart';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {

  @Input() item: MenuItem;

  @LocalStorage() cart: Webstorable & Cart;

  constructor(private analitics: AngularFireAnalytics) {}

  ngOnInit() {}

  addItem(){
    this.analitics.logEvent('item-add', this.item);
    this.cart.items.push(this.item);
    this.cart.save();
  }

}
