import { Component } from '@angular/core';
import { LocalStorage } from 'ngx-store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'menubot';
  @LocalStorage() darkmode = false;
}
