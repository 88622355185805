import { Component, OnInit, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SharedStorage } from 'ngx-store';
import { Menu } from '../interfaces/menu';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @SharedStorage() menu: Menu;

  constructor() {}

  ngOnInit() {}

}
