import { Pipe, PipeTransform } from '@angular/core';
import { CartItem } from '../interfaces/cart';

@Pipe({
  name: 'itemSum'
})
export class ItemSumPipe implements PipeTransform {

  transform(items: CartItem[], ...args: any[]): any {
    return items.reduce((val, item) => val += item.price, 0);
  }

}
