import { Component, OnInit } from '@angular/core';
import { LocalStorage, Webstorable, } from 'ngx-store';
import { Cart } from '../interfaces/cart';
import { Order } from '../interfaces/order';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  @LocalStorage() cart: Webstorable & Cart;
  @LocalStorage() orders: Webstorable & Order[];

  constructor(
    private router: Router,
    private analitics: AngularFireAnalytics,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
  }

  order() {
    const order = {
      items: this.cart.items,
      timestamp: new Date(),
      status: 'pending',
    };
    this.orders.splice(0, 0, order);
    this.orders.save();
    this.cart.items = [];
    this.cart.save();
    this.analitics.logEvent('new-order', order);
    this.router.navigate(['..', 'orders'], {relativeTo: this.activatedRoute});
  }

}
