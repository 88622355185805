import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { LocalStorage } from 'ngx-store';
import { Order } from '../interfaces/order';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  @LocalStorage() orders: Order[];

  constructor() { }

  ngOnInit() {
    
  }

}
