import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-menu-category',
  templateUrl: './menu-category.component.html',
  styleUrls: ['./menu-category.component.scss']
})
export class MenuCategoryComponent implements OnInit {

  @Input()
  category: any;

  constructor() { }

  ngOnInit() {
    console.log('cat', this.category)
  }

}
