import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({
  name: 'sortBy',
  pure: false,
})
export class SortByPipe implements PipeTransform {

  transform(value: any[], order: 'desc' | 'asc' = 'desc', column: string = ''): any[] {
    if (!value || !order) { return value; } // no array
    if (!column || column === '') { return orderBy(value); } // sort 1d array
    if (value.length <= 1) { return value; } // array with only one item
    return orderBy(value, [column], order);
  }
}
